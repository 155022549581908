<template>
	<div id="box">
		<div class="box">
			<div class="left">
				<div class="top">
					<div class="topleft">
						<div class="topleftone">
							<span>行业</span>
							<el-select v-model="hyvalue" clearable placeholder="请选择行业">
							    <el-option
							      v-for="item in hyoptions"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value">
							    </el-option>
							</el-select>
							<span>状态</span>
							<el-select v-model="ztvalue" clearable placeholder="请选择状态">
							    <el-option
							      v-for="item in ztoptions"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value">
							    </el-option>
							</el-select>
						</div>
						<div class="toplefttwo">
							<span>关键字</span>
							<el-input
							    placeholder="请输入公司名称或者产品品类"
							    prefix-icon="el-icon-search"
							    v-model="input2">
							  </el-input>
						</div>
					</div>
					<div class="topright">
						<!--<el-button>数据导入</el-button>
						<el-button>数据导出</el-button>-->
						<el-button @click="select">搜索</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
				</div>
				<div class="bom">
					<div class="rader"></div>
					<div class="qiu">印刷产业</div>
					<div class="one" @click="active(1)" :style="site[0]">供应商(上游)</div>
					<div class="two" @click="active(2)" :style="site[1]">制造企业(中游)</div>
					<div class="three" @click="active(3)" :style="site[2]">商贸平台(下游)</div>
				</div>
				<div class="bom2">
					<div class="icon">
						<img src="../../assets/img/下箭头.png" />
					</div>
					<div class="bombox">
						<div :class="[activeindex==i?'activedd':'','bomitem']" v-for="(item,i) in itemlist" :key="i" @click="acctive(item.businessId,i)">
							{{item.businessName}}
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="righttop">
					共发现{{total}}家
				</div>
				<div class="rightitem" v-for="(item,i) in qiyelist" :key='i'>
					<div class="itemone">
						<p>{{page==1&&(i+1)==10?1:''}}{{page-1==0?'':(i+1)%10==0?page:(page-1)}}{{(i+1)%10==0?0:i+1}}</p>
						<h2 @mousemove="move(i)" @mouseout="out()">{{item.companyName}}</h2>
						<a @click="xiangq(item.companyId,item.companyName)">详情</a>
						<span v-show="nameshow&&nameid==i">{{item.companyName}}</span>
					</div>
					<div class="itemtwo" >
						<p class="xucun" v-if="item.regStatusName">{{item.regStatusName}}</p>
						<p class="type" v-if="item.industryName">{{item.industryName}}</p>
						<p class="qiye" v-if="item.businessName">{{item.businessName}}</p>
					</div>
				</div>
				<div class="zan" v-if="!qiyelist">
					暂无信息
				</div>
				<div class="page">
					 <el-pagination
					      @size-change="handleSizeChange"
					      @current-change="handleCurrentChange"
					      :current-page="page"
					      :page-sizes="[10, 20, 30, 40]"
					      :page-size="limit"
					      layout="prev, pager, next, jumper"
						  :pager-count="pagecount"
						  small
					      :total="total">
					    </el-pagination>
				</div>
			</div>
		</div>
    <frimpup :frimshow="frimshow" :frimobj="frimobj" @frimswitch="firmsh"></frimpup>
	</div>
</template>

<script>
  import frimpup from "@/components/firmpup"
  import {tenantBusiness,ScopeByPatent,industryCategory,readBcode,listCompany} from "@/api/chain/chain"
	export default {
		data() {
			return {
				site:[{left:'0',top:'84px',},{left: '42%',top: '169px'},{left: '84.5%',top: '84px'}],
				site2:[{left:'0',top:'84px'},{left: '42%',top: '169px'},{left: '84.5%',top: '84px',}],
				act:2,
				itemlist:[],
				hyoptions:[],
				hyvalue:'',
				ztoptions:[],
				ztvalue:'',
				input2:'',
				page:1,
				limit:10,
				businessid:'',//经营分类id
				qiyelist:[],//企业数组
				nameshow:false,
				nameid:null,
				total:0,
				pagecount:5,
        activeindex:null,
        frimshow:false,
        frimobj:{},
			}
		},
		mounted() {
			this.first()
			this.first2()
			this.first3()
		},
		methods: {
      xiangq(id,name){
        tenantBusiness(id).then(res=>{
          this.frimshow=true
          if(res.data.data){
            res.data.data.foundDate=res.data.data.foundDate?this.$moment(res.data.data.foundDate).format('YYYY-MM-DD'):null
            res.data.data.tenantName=name
            this.frimobj=res.data.data
          }
        })
      },
			acctive(id,i){
        this.activeindex=i
				this.businessid=id
				this.page=1
				this.first3()
			},
			reset(){
				this.page=1
				this.hyvalue=''
				this.ztvalue=''
				this.input2=''
        this.active(2)
			},
			select(){
				this.page=1
				this.first3()
			},
			handleSizeChange(val) {
			    console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.page=val
				this.first3()
			},
			move(i){
				this.nameid=i
				setTimeout(()=>{
					this.nameshow=true
				},1500)
			},
			out(){
				this.nameid=null
				this.nameshow=false
			},
			first(){
				ScopeByPatent(this.act).then(res=>{
					if(res.data){
						this.itemlist=res.data
					}else{
					}
				})
			},
			first2(){
				var _this=this
				industryCategory().then(res=>{
					if(res.data.data){
						res.data.data.forEach(item=>{
							_this.hyoptions.push({
								label:item.industryName,
								value:item.industryId
							})
						})
					}
				})
				readBcode().then(res=>{
					if(res.data.data){
						res.data.data.forEach(item=>{
							_this.ztoptions.push({
								label:item.codeLabel,
								value:item.codeValue
							})
						})
					}
				})
			},
			first3(){
				listCompany(
					this.page,
					this.limit,
					this.input2,
					this.hyvalue,
					this.businessid,
					this.ztvalue
				).then(res=>{
					if(res.data.data){
						this.total=res.data.count
						this.qiyelist=res.data.data
					}else{
						this.qiyelist=null
						this.total=0
					}
				})
			},
			active(val) {
        this.activeindex=null
				this.act=val
				var [a,b,c]=this.site2
				var x=null
				if(val==1){
					this.site=[b,a,c]
				}else if(val==3){
					this.site=[a,c,b]
				}else if(val==2){
					this.site=[a,b,c]
				}
				this.first()
        this.businessid=val
        this.page=1
        this.first3()
			},
      firmsh(val){
        this.frimshow=val
      },
		},
    components:{
      frimpup
    }
	}
</script>

<style scoped="scoped" lang="less">
	.box{
		width: 100%;
		background-color: #f2f3f5;
		border-radius: 4px;
		display: flex;
	}
	.left{
		width: 70%;
		background-color: #FFFFFF;
		padding: 4px;
		border-radius: 4px;
		max-height: 841px;
		overflow-y: auto;
		.bom{
			width: 100%;
			height: 253px;
			position: relative;
      background-image: url(../../assets/img/背景.png);
      background-size:100% 100%;
      border-radius: 4px;
			.qiu{
				position: absolute;
				width: 150px;
				height: 150px;
				// background-color: #0970E9;
        background-image: url(../../assets/img/圆.png);
        background-size: 100% 100%;
				border-radius: 50%;
        color: #FFFFFF;
        font-size: 18px;
        text-align: center;
				left: 0;
				right: 0;
				margin: 0 auto;
				line-height: 150px;
        z-index: 1;
			}
			.rader{
				position: absolute;
				width: 100%;
				height: 189px;
				// background-color: #1479EF;
				// background-image: -webkit-linear-gradient(bottom,rgba(255,255,255,0),rgba(192,216,243,0.25),rgba(91,152,224,0.67),rgba(14,104,210,1));
				// background-image: -o-linear-gradient(bottom,rgba(255,255,255,0),rgba(192,216,243,0.25),rgba(91,152,224,0.67),rgba(14,104,210,1));
				// background-image: -moz-linear-gradient(bottom,rgba(255,255,255,0),rgba(192,216,243,0.25),rgba(91,152,224,0.67),rgba(14,104,210,1));
				// background-image: linear-gradient(to bottom,rgba(255,255,255,0),rgba(192,216,243,0.25),rgba(91,152,224,0.67),rgba(14,104,210,1));
        background-image: url(../../assets/img/线.png);
        background-size: 100% 132%;
				border-radius: 50%;
				top: 32px;
			}
			.one,.two,.three{
				position: absolute;
				width: 132px;
				text-align: center;
				line-height: 75px;
				font-size: 18px;
				font-weight: bold;
				height: 75px;
				background-color: #0970E9;
				transition: all 0.3s;
				color: #FFFFFF;
				border-radius: 4px;
        background-image: url(../../assets/img/框.png);
        background-size: 100% 100%;
			}
			.one:hover{
				cursor: pointer;
			}
			.two:hover{
				cursor: pointer;
			}
			.three:hover{
				cursor: pointer;
			}
		}
		.bom2{
			width: 100%;
			.icon{
				width: 100%;
				height: 80px;
				text-align: center;
				line-height: 80px;
				img{
					height: 62px;
					width: 100px;
				}
			}
			.bombox{
				width: 100%;
				height: 300px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				.bomitem{
					width: 127px;
					height: 70px;
					text-align: center;
					line-height: 70px;
					background-color: #003B80;
					margin: 0 10px;
					color: #FFFFFF;
					border-radius: 4px;
				}
				.bomitem:hover{
					cursor: pointer;
				}
        .activedd{
          background-color: #0970E9;
        }
			}
		}
		.top{
			width: 100%;
			height: 200px;
			display: flex;
			align-items: center;
			.topleft{
				width: 59%;
				height: 100%;
				.topleftone{
					width: 100%;
					height: 50%;
					display: flex;
					align-items: center;
					span{
						margin: 0 10px;
            display: inline-block;
            width: 40px;
					}
				}
				.toplefttwo{
					width: 100%;
					height: 50%;
					display: flex;
					align-items: center;
					span{
						margin: 0 10px;
            display: inline-block;
            width: 50px;
					}
				}
				.toplefttwo>.el-input{
					width: 86%;
				}
			}
			.topright{
				width: 37%;
				height: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				button{
					width: 100px;
				}
				button:nth-child(3){
					margin-left: 2px;
				}
			}
		}
	}
	.right{
		width: 29%;
		height: 700px;
		margin-left: auto;
		border-radius: 4px;
		height: 841px;
		overflow-y: auto;
		position: relative;
		.zan{
			width: 100%;
			height: 50px;
			background-color: #fff;
			margin: 10px 0;
			text-align: center;
			line-height: 50px;
		}
		.righttop{
			width: 100%;
			height: 50px;
			border-radius: 4px;
			background-color: #FFFFFF;
			line-height: 42px;
			padding: 4px;
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.rightitem{
			width: 100%;
			height: 100px;
			background-color: #FFFFFF;
			border-radius: 4px;
			padding: 4px;
			margin: 10px 0;
			.itemone{
				width: 100%;
				height:50px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				position: relative;
				p{
          min-width: 30px;
          padding: 5px;
					font-size: 14px;
					background-color: #B7D6F5;
					text-align: center;
					border-radius: 4px;
				}
				h2{
					font-size: 18px;
					width: 232px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				a{
					color: #1296DB;
				}
        a:hover{
          cursor: pointer;
        }
				span{
					position: absolute;
					top: -5px;
				}
			}
			.itemtwo{
				width: 100%;
				height: 50px;
				display: flex;
				align-items: center;
				font-size: 14px;
				p{
					background-color: #F5EDED;
					text-align: center;
					line-height: 30px;
					border-radius: 4px;
					padding: 4px 9px;
					margin: 0 4px;
				}
				p:nth-child(1){
					margin-left: 14px;
				}
				.type{
					background-color: #F0FAF2;
				}
				.qiye{
					background-color: #FAF5EE;
				}
			}
		}

		.page{
			width: 100%;
			height: 50px;
			background-color: #FFFFFF;
			border-radius: 4px;
			display: flex;
			align-items: center;
			position: sticky;
			bottom: 0;
		}
		.page/deep/.el-pagination__jump {
		    margin-left: 6px;
		}
	}

</style>
