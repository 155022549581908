import fetch from "@/api/fetch"

// 详情
export function tenantBusiness(tenantId){
	const data ={tenantId}
	return fetch({
		url:'/api/api/rest/company/gov/tenantBusiness',
		method:'post',
		data
	})
} 
// first1
export function ScopeByPatent(act){
	return fetch({
		url:'/apg/longGang/company/listBusinessScopeByPatent?scopeId='+act,
		method:'get',
	})
}
// first2
export function industryCategory(){
	return fetch({
		url:'/apg/longGang/BasePortal/industryCategory',
		method:'get',
	})
}
export function readBcode(){
	return fetch({
		url:'/apg/longGang/BasePortal/readBcode?codeType=REG_STATUS',
		method:'get',
	})
}
// first3
export function listCompany(page,limit,kw,industryId,businessScope,regStatus){
	const data ={page,limit,kw,industryId,businessScope,regStatus}
	return fetch({
		url: '/apg/longGang/company/listCompany',
		method:'post',
		data
	})
}

